import React from "react";
import "./index.css";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { CardMedia } from "@mui/material";
import Login from "./components/login";
import Register from "./components/register";
import Home from "./components/home";
import Profile from "./components/profile";
import Propuestas from "./components/propuestas";
import Proyectos from "./components/proyectos";
import Notifications from "./components/notifications";
import Publish from "./components/publish";
import PublishProposal from "./components/publishProposal";
import Edit from "./components/edit";
import ChatBox from "./components/chatbox";
import Chats from "./components/chats";
import Header3 from "./components/Header3";
import Project from "./components/project";
import Members from "./components/members";
import Atras from "./components/atras";
import BottomNav from "./components/BottomNav";
import Terms from "./components/terms";
import ProjectProposals from './components/projectProposals';
import Privacidad from "./components/privacyPolicy";
import { isLoggedIn, endSession } from "./session";
import logo from "./imgs/logo.png";
import Membership from "./components/membership";

const Loading = () => (
  <div className="loading-container">
    <CardMedia
      className="loading-img"
      alt="Cargando..."
      component="img"
      image={logo}
    />
  </div>
);

const App = () => {
  const [user, setUser] = React.useState(null);
  const [loading, setLoading] = React.useState(true); // Estado para el mensaje de carga

  // Verificar si el usuario está autenticado al cargar la aplicación
  React.useEffect(() => {
    const checkAuth = async () => {
      try {
        const user = await isLoggedIn();
        setUser(user);
      } catch (error) {
        console.error("Error al verificar la autenticación:", error);
      } finally {
        setLoading(false); // Indica que la verificación ha terminado
      }
    };

    checkAuth();
  }, []);

  // Función para cerrar sesión
  const handleLogout = async () => {
    try {
      await endSession();
      setUser(null);
    } catch (error) {
      console.error("Error al cerrar sesión:", error);
    }
  };

  return (
    <Router>
      {/* Muestra un mensaje de carga si la verificación está en curso */}
      {loading ? (
        <Loading />
      ) : (
        <Routes>
          <Route
            path="/"
            element={user ? <Home handleLogout={handleLogout} /> : <Login />}
          />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/home" element={<Home handleLogout={handleLogout} />} />
          <Route path="/notifications" element={<Notifications />} />
          <Route path="/propuestas" element={<Propuestas />} />
          <Route path="/chats" element={<Chats />} />
          <Route
            path="/chatbox/:projectId/:proposalId/:freelancerUid/:authUser"
            element={<ChatBox />}
          />
          <Route path="/project/:projectId/proposals/:proposalId" element={<ProjectProposals />} />
          <Route path="/project/:projectId" element={<Project />} />
          <Route path="/publish" element={<Publish />} />
          <Route path="/publishProposal" element={<PublishProposal />} />
          <Route path="/header3/:projectId" element={<Header3 />} />
          <Route path="/atras" element={<Atras />} />
          <Route path="/bottomNav" element={<BottomNav />} />
          <Route path="/members" element={<Members />} />
          <Route path="/proyectos" element={<Proyectos />} />
          <Route path="/edit" element={<Edit />} />
          <Route path="/membership" element={<Membership />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/privacyPolicy" element={<Privacidad />} />
        </Routes>
      )}
    </Router>
  );
};

export default App;
