import React, { useEffect, useState, useCallback } from "react";
import {
  Container,
  AppBar,
  Toolbar,
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
  Badge,
  Card,
  CardContent,
  IconButton,
  styled,
  Button,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { isLoggedIn } from "../session";
import { db } from "../firebase";
import {
  collection,
  query,
  where,
  updateDoc,
  doc,
  orderBy,
  limit,
  onSnapshot
} from "firebase/firestore";
import BottomNav from "./BottomNav";
import Atras from "./atras";
import { Refresh as RefreshIcon } from "@mui/icons-material";

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  color: theme.palette.text.primary,
}));

const StyledCard = styled(Card)(({ theme }) => ({
  width: '90%',
  marginBottom: theme.spacing(2),
  transition: "0.3s",
  "&:hover": {
    transform: "translateY(-5px)",
    boxShadow: theme.shadows[4],
  },
}));

const StyledBadge = styled(Badge)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  "& .MuiBadge-badge": {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
  },
}));

const LoadingContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "50vh",
}));

const NOTIFICATIONS_PER_PAGE = 10;

const Notifications = () => {
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const navigate = useNavigate();

  const fetchNotifications = useCallback(async () => {
    let unsubscribe = null;
    try {
      const user = await isLoggedIn();
      if (!user) {
        navigate("/login");
        return null;
      }

      const uid = user.uid;

      const notificationsRef = collection(db, "notifications");
      const notificationsQuery = query(
        notificationsRef,
        where("userId", "==", uid),
        orderBy("timestamp", "desc"),
        limit(page * NOTIFICATIONS_PER_PAGE)
      );

      unsubscribe = onSnapshot(notificationsQuery, (snapshot) => {
        try {
          const notificationsData = snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }));
          console.log("Real-time notifications update:", notificationsData);
          setNotifications(notificationsData);
          setError(null);
        } catch (error) {
          console.error("Error processing notifications update:", error);
          setError("Failed to process notifications update. Please try again.");
        } finally {
          setLoading(false);
        }
      }, (error) => {
        console.error("Error in notifications listener:", error);
        setError("Failed to listen for notifications. Please try again.");
        setLoading(false);
      });

    } catch (err) {
      console.error("Error fetching notifications:", err);
      setError("Failed to load notifications. Please try again.");
      setLoading(false);
    }

    return unsubscribe;
  }, [navigate, page]);

  useEffect(() => {
    let unsubscribe = null;

    const setupNotifications = async () => {
      unsubscribe = await fetchNotifications();
    };

    setupNotifications();

    return () => {
      if (unsubscribe && typeof unsubscribe === 'function') {
        unsubscribe();
      }
    };
  }, [fetchNotifications]);

  const renderNotificationContent = (notification) => {
    switch (notification.type) {
      case "chat":
        return (
          <>
            <Typography variant="subtitle1" color="primary">
              Nuevo mensaje de chat
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {notification.message}
            </Typography>
          </>
        );
      case "project_skill":
        return (
          <>
            <Typography variant="subtitle1" color="primary">
              Nuevo proyecto coincidente
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {notification.message}
            </Typography>
          </>
        );
      case "new_proposal":
        return (
          <>
            <Typography variant="subtitle1" color="primary">
              Nueva propuesta recibida
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {notification.message}
            </Typography>
          </>
        );
      default:
        return (
          <Typography variant="body2" color="textSecondary">
            {notification.message}
          </Typography>
        );
    }
  };

  const handleNotificationClick = async (notification) => {
    try {
      const notificationRef = doc(db, "notifications", notification.id);
      await updateDoc(notificationRef, { read: true });

      switch (notification.type) {
        case "chat":
          navigate(`/chatbox/${notification.projectId}/${notification.proposalId}/${notification.userId || ""}/${notification.projectUid}`);
          break;
        case "project_skill":
          navigate(`/project/${notification.projectId}`);
          break;
        case "new_proposal":
          navigate(`/project/${notification.projectId}/proposals/${notification.proposalId}`);
          break;
        default:
          console.log("Unknown notification type:", notification.type);
      }
    } catch (error) {
      console.error("Error updating notification:", error);
      setError("Failed to update notification. Please try again.");
    }
  };

  const handleLoadMore = () => {
    setPage(prevPage => prevPage + 1);
  };

  const handleRefresh = () => {
    setLoading(true);
    setPage(1);
    fetchNotifications();
  };

  if (loading) {
    return (
      <LoadingContainer>
        <CircularProgress />
      </LoadingContainer>
    );
  }

  return (
    <Container maxWidth="sm">
      <StyledAppBar position="fixed">
        <Toolbar>
          <Atras color="inherit" />
          <Typography variant="h6" component="div" sx={{ flexGrow: 1, textAlign: 'center' }}>
            Notificaciones
          </Typography>
          <IconButton color="inherit" onClick={handleRefresh}>
            <RefreshIcon />
          </IconButton>
        </Toolbar>
      </StyledAppBar>

      <Box sx={{ mt: 10, mb: 10 }}>
        {error && (
          <Typography color="error" align="center" sx={{ mb: 2 }}>
            {error}
          </Typography>
        )}
        {notifications.length > 0 ? (
          <>
            <List>
              <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", width: "100%" }}>
                {notifications.map((notification) => (
                  <StyledBadge key={notification.id} color="error" variant="dot" invisible={notification.read}>
                    <StyledCard>
                      <CardContent>
                        <ListItem
                          button
                          onClick={() => handleNotificationClick(notification)}
                        >
                          <ListItemText
                            primary={renderNotificationContent(notification)}
                          />
                        </ListItem>
                      </CardContent>
                    </StyledCard>
                  </StyledBadge>
                ))}
              </Box>
            </List>
            {notifications.length % NOTIFICATIONS_PER_PAGE === 0 && (
              <Button fullWidth variant="outlined" onClick={handleLoadMore} sx={{ mt: 2 }}>
                Cargar más
              </Button>
            )}
          </>
        ) : (
          <Typography variant="body1" color="textSecondary" align="center">
            No tienes notificaciones nuevas.
          </Typography>
        )}
      </Box>

      <BottomNav />
    </Container>
  );
};

export default Notifications;